import React, { useEffect } from 'react';
import { LogError } from 'utils/logging';
import Head from 'next/head';
import request from 'utils/request';
import styles from '../base-theme/maintenance.module.css';

export default function MaintenancePage(): React.ReactElement {
  useEffect(() => {
    LogError('MaintenancePage');
  }, []);

  return (
    <>
      <Head>
        <title>Site Maintenance | We&apos;ll Be Back Soon</title>
        <meta
          name="description"
          content="Our site is currently undergoing scheduled maintenance. We'll be back shortly."
        />
      </Head>
      <div className={styles.container}>
        <div className={styles.content}>
          <svg
            width="300"
            height="300"
            viewBox="0 0 300 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="150"
              cy="150"
              r="145"
              stroke="#E5E7EB"
              strokeWidth="10"
            />
            <path
              d="M150 70V150L200 200"
              stroke="#6B7280"
              strokeWidth="12"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M85 85L95 95M205 85L215 95M85 215L95 205M215 205L205 215M70 150H80M220 150H230M150 70V80M150 220V230"
              stroke="#9CA3AF"
              strokeWidth="8"
              strokeLinecap="round"
            />
            <circle cx="150" cy="150" r="12" fill="#6B7280" />
            <path
              d="M120 40L150 50L180 40"
              stroke="#6B7280"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
          <h1 className={styles.title}>We&apos;ll be back soon!</h1>
          <p className={styles.message}>
            We&apos;re currently performing scheduled maintenance to improve
            your experience. Please check back shortly.
          </p>
          <div className={styles.statusContainer}>
            <div className={styles.status}>
              <span className={styles.dot} />
              System maintenance in progress
            </div>
            {process.env.NODE_ENV === 'development' && (
              <button
                type="button"
                onClick={() =>
                  request({
                    method: 'post',
                    url: `/api/testing/update-dev-site`,
                    body: {
                      reset: true,
                    },
                  }).then(() => {
                    window.location.href = '/';
                  })
                }
                className={styles.status}
              >
                Reset Environment Variables
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
